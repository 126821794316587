<script>
/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkCheckbox from "@components/input/AkCheckbox";
import AkInputText from "@components/input/AkInputText";
import AkInputNumber from "@components/input/AkInputNumber";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import multiLineMixin from "@mixins/multiLineMixin";
import roleMixin from "@mixins/roleMixin";

/* SERVICES */
import bilanMaterielService from "@services/bilanMaterielService";

export default {
  components: {AkFormView, AkInputText, AkInputNumber, AkCheckbox, AkDropdown},
  mixins: [randomRef, roleMixin, multiLineMixin],
  metaInfo() {
    return {
      title: "bilan.details",
    }
  },
  data() {
    return {
      current: {},
      volumeUnits: [
        {label: this.$t('bilan_materiel.v3m3'), value: "v3m3"},
        {label: this.$t('bilan_materiel.v4a45m3'), value: "v4a45m3"},
        {label: this.$t('bilan_materiel.v5m3'), value: "v5m3"},
        {label: this.$t('bilan_materiel.v6m3'), value: "v6m3"},
        {label: this.$t('bilan_materiel.v7a8m3'), value: "v7a8m3"}
      ],
    }
  },
  mounted() {
    this.getRef().showTotalLoader();
    
    let msg = this.$route.query.msg;
    if (msg && msg !== "") {
      this.getRef().success(msg);
    }
    
    let p1 = bilanMaterielService.findById(this.$route.params.id);
    p1.then(data => this.current = data);
    
    Promise.all([p1]).then(()=>{
      this.current.problemComment = this.getTextParsed(this.current.problemComment);
      this.getRef().hideLoader();
    });
  }
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan.details')">
    <template v-slot:action>
      <RouterLink v-if="current.id !== undefined && this.canEditEquipment() && !current.year && this.canEditOrDeleteReview(current)"
                  :to="{ name: 'bilanMaterielUpdate', params: {id: current.id}}"
                  class="btn btn-inverse-primary btn-xs"><i
        class="fe fe-edit pr-1"/>{{ $t('update') }}
      </RouterLink>
    </template>
    <template v-slot:form>
      <div class="form-row">
        <AkCheckbox :disabled=true
                    v-model="current.mule"
                    :label="$t('bilan_materiel.new_mule')"
                    class-name="col-md-3"/>
      </div>
      <div class="form-row">
        <AkInputText :disabled=true v-model=current.agerLabel class-name="col-md-3" :label="$t('ager_label')"/>
        <AkInputText v-if=!current.mule :disabled=true v-model=current.uerLabel class-name="col-md-3" :label="$t('uer_label')"/>
        <AkInputText v-if=!current.mule :disabled=true v-model=current.ceiLabel class-name="col-md-3" :label="$t('cei_label')"/>
        <AkInputText v-if=!current.mule :disabled=true v-model=current.circuitAxis class-name="col-md-3" :label="$t('circuit_label')"/>
      </div>
      <h5>{{ $t('bilan_materiel.truck_info') }}</h5>
      <div class="form-row">
        <AkInputText :disabled=true
                    :label="$t('bilan_materiel.truck_type')"
                    v-model="current.truckType"
                    class-name="col-md-4"/>
        <AkInputText :disabled=true
                     :label="$t('bilan_materiel.brand')"
                     v-model="current.brand"
                     class-name="col-md-4"/>
      </div>
      <div class="form-row">
        <AkCheckbox :disabled=true
                    v-model="current.location"
                    :label="$t('bilan_materiel.rent')"
                    class-name="col-md-3"/>
      </div>
      <h5>{{ $t('bilan_materiel.salting') }}</h5>
      <div class="form-row">
        <AkDropdown :disabled=true
                    :label="$t('bilan_materiel.salt_unit')"
                    v-model="current.volumeUnit"
                    :options=this.volumeUnits
                    option-value="value"
                    option-label="label"
                    class-name="col-md-4"/>
      </div>
      <div class="form-row">
        <AkCheckbox :disabled=true
                    v-model="current.brine"
                    :label="$t('bilan_materiel.brine')"
                    class-name="col-md-4"/>
      </div>
      <h5>{{ $t('bilan_materiel.repairs_and_problems') }}</h5>
      <div class="form-row">
        <AkInputNumber :disabled=true
                       :label="$t('bilan_materiel.nb_repair_materiel_vh')"
                       v-model="current.repairNumber"
                       class-name="col-md-4"/>
        <div class="form-group col-md-8">
          <label>{{ $t('bilan_materiel.problem_met_and_suggestions') }}</label>
          <div class="form-control" style="background: #e9ecef; color: #495057; opacity: 1; display: table;" v-html="current.problemComment"></div>
        </div>
      </div>
    </template>
  </AkFormView>
</template>